<template>
  <div class='create-promocode'>
    <base-input-text
      v-model='v$.partner.name.$model'
      label='Nome do parceiro'
      placeholder='Digite o nome do parceiro'
    />

    <base-input-text
      v-model='v$.partner.email.$model'
      label='E-mail'
      type='email'
      placeholder='Digite o E-mail'
      :error="errors.client"
    />

    <div  class='d-flex'>
      <base-button
        danger
        class='w-50 '
        @click="close()"
      >
        Cancelar
      </base-button>

      <base-button
        @click='handleSubmit'
        :disabled='v$.$invalid'
        class='w-50 space'
      >
        {{ partnerId ? 'Atualizar parceiro' : 'Gerar chave' }}
      </base-button>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/validators'
import { email } from '@vuelidate/validators'
export default {
  name: 'AddApiKey',
  setup() {
    return { v$: useVuelidate() }
  },
  props:{
    partnerId: { type: String, default: '' },
  },
  data() {
    return {
      errors: {
        client: ''
      }
    }
  },
  async created() {
    if (this.partnerId) {
      const response = await this.handleGetPartner(this.partnerId)
      if (!response.data) {
        this.$bvToast.toast(response.message, {
          title: 'Aviso',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
      }
    }else {
      await this.resetPartner()
    }
  },
  computed: {
    ...mapGetters('apiKey', {
      partner: 'getPartner'
    })
  },
  validations: {
    partner: {
      name: { required },
      email: {required, email},
    }
  },
  methods: {
    ...mapActions('apiKey', {
      handleCreatePartner: 'handleCreatePartner',
      handleUpdatePartner: 'handleUpdatePartner',
      handleGetPartner: 'handleGetPartner',
      resetPartner: 'resetPartner',
      handleGetEmailPartner: 'handleGetEmailPartner',
    }),
    close(){
      this.$emit("close")
    },
    async handleSubmit() {

      let response = '';
      if (this.partnerId) {
        response = await this.handleUpdatePartner(this.partnerId)
      } else {
        const partner = await this.handleGetEmailPartner()
        if(partner.data) {
          this.$bvToast.toast('O já tem um parceiro cadastrado com esse E-mail.', {
            title: 'Aviso',
            variant: 'danger',
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000
          })
          return
        }
        response = await this.handleCreatePartner()
      }

      let message = response.message
      if (response.errors) {
        message = response.errors
        this.$bvToast.toast(message, {
          title: 'Aviso',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
      }
      this.$emit('show-toast-response', message);
      this.resetPartner()
    },
    async remove(id) {
      const response = await this.removePromocode(id)

      let message = ''
      let variant = ''

      if (response.message) {
        message = response.message
        variant = 'success'
        this.handleClearPromocode()
      }
      if (response.errors) {
        message = response.errors
        variant = 'danger'
      }

      this.$bvToast.toast(message, {
        title: 'Aviso',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
    }
  }

}
</script>

<style lang='scss' scoped>
.create-promocode {
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    display: inline-block;
  }

  .space {
    margin-left: 10px;
  }

  h4 {
    font-weight: bold;
    border-bottom: 2px solid var(--blue);
    color: var(--blue);
    padding-bottom: 4px;
  }
}
</style>
