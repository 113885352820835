<template>
  <header>
    <div class="inputs">
      <base-input-text
        v-model="startDate"
        type="date"
      />
      <span>a</span>
      <base-input-text
        v-model="endDate"
        type="date"
      />
      <base-button 
        @click="handleChangeItems"
        :disabled="!isValid()"
      >
        Filtrar
      </base-button>
    </div>
  </header>
</template>

<script>

export default {
  name: 'HeaderApiKey',
  props: { },
  data(){
    return {
      startDate: '',
      endDate: ''
    }
  },
  methods: {
    isValid(){
      if(!this.startDate || !this.endDate) {
        return false
      }

      return true
    },
    async handleChangeItems(){
      const params =  {
        startDate: new Date(this.startDate).toISOString().split('T')[0],
        endDate: new Date(this.endDate).toISOString().split('T')[0],
      }

      this.$emit('change-api-key', params)
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .inputs {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      font-weight: bold;
    }
  }
}
</style>
