<template>
  <div class='surveys-view mt-3'>
    <dialog-confirmation
      :description='message'
      :is-open='openDialog'
      @close='openDialog = false'
      @confirmed='deletePartner'
    />

    <dialog-confirmation
      :description='message'
      :is-open='openDialogUpdateApikeyPartner'
      @close='openDialogUpdateApikeyPartner = false'
      @confirmed='updateApikeyPartner'
    />

    <b-card no-body>
      <b-tabs pills card>
        <b-tab @click="setBreadcrumbTabsName('Cadastro de parceiros')" no-body title='Cadastro de parceiros' class='p-2'>
          <div class='py-3' v-if='addKey'>
            <add-api-key :partnerId='partnerId' :readOnly='false' @close='close'
                         @show-toast-response='showToastResponse' />
          </div>

          <div v-else class='py-3'>
            <div class='d-flex py-3 justify-content-between'>
              <div>
                <api-key-header @change-api-key='handleChangeApiKey' />
              </div>

              <div class='search d-flex'>
                <base-input-text
                  v-model='filter'
                  placeholder='Pesquisar'
                  type='text'
                />
                <base-button
                  :disabled='!filter'
                  @click="filter = ''"
                >
                  Limpar
                </base-button>
              </div>

              <base-button @click='show()' class='w-25'>
                <b>Cadastrar Chave</b>
              </base-button>
            </div>

            <api-key-table
              :partners='partners'
              :partnersMeta='partnersMeta'
              @change-partners='handleChangeApiKey'
              @remove-partner='removePartner'
              @update-partner='updatePartner'
              @update-apikey='showUpdateApikeyPartner'
            />
            <p class='text-center' v-if='partners.length < 1'>Nenhum item encontrado</p>
          </div>
        </b-tab>

        <b-tab @click="setBreadcrumbTabsName('Histórico de pesquisas')" no-body title='Histórico de pesquisas'>
          <log-api/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import ApiKeyTable from '@/components/ApiKey/Table'
import ApiKeyHeader from '@/components/ApiKey/Header'
import AddApiKey from '@/components/ApiKey/AddApiKey'
import { mapActions, mapGetters } from 'vuex'
import DialogConfirmation from '@/components/DialogConfirmation.vue'
import LogApi from '@/views/LogApi'
import _ from 'underscore'


export default {
  name: 'ApiKey',
  data() {
    return {
      breadcrumbTabsName: 'Cadastro de parceiros',
      logUpdate: {},
      openDialog: false,
      openDialogUpdateApikeyPartner: false,
      openDialogModalSeeUpdateLogs: false,
      partnerRemove: '',
      partnerApiKey: '',
      addKey: false,
      partnerId: '',
      message: '',
      filter: ''
    }
  },
  components: {
    ApiKeyTable,
    DialogConfirmation,
    AddApiKey,
    ApiKeyHeader,
    LogApi
  },
  computed: {
    ...mapGetters('apiKey', {
      partners: 'getPartners',
      partnersMeta: 'getPartnersMeta'
    })
  },
  watch: {
    filter: _.debounce(async function() {
      await this.handleChangeApiKey({ filter: this.filter })
    }, 100)
  },
  methods: {
    ...mapActions('apiKey', {
      handleGetPartners: 'handleGetPartners',
      handleGetPartner: 'handleGetPartner',
      resetPartner: 'resetPartner',
      handleDeletePartner: 'handleDeletePartner',
      handleUpdatePartnerApiKey: 'handleUpdatePartnerApiKey'
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    async showToastResponse(message) {
      this.$bvToast.toast(message, {
        title: 'Aviso',
        variant: 'success',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      await this.handleGetPartners()
      this.close()
    },
    show() {
      this.partnerId = ''
      this.addKey = true
    },
    close() {
      this.addKey = false
      this.resetPartner()
    },
    removePartner(item) {
      this.partnerRemove = item
      this.openDialog = true
      this.message = 'Você está prestes a excluir um parceiro. Tem certeza que deseja realizar esta ação?'
    },

    async showUpdateApikeyPartner(item) {
      this.partnerApiKey = item
      this.openDialogUpdateApikeyPartner = true
      this.message = `Você está prestes a gerar uma nova chave para o parceiro ${item.name}. Tem certeza que deseja realizar esta ação?`
    },
    async updateApikeyPartner() {
      this.openDialogUpdateApikeyPartner = false
      const response = await this.handleUpdatePartnerApiKey(this.partnerApiKey._id)
      let message = response.message
      let variant = 'success'

      if (response.errors) {
        message = response.errors
        variant = 'danger'
      }

      this.$bvToast.toast(message, {
        title: 'Aviso',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      this.openDialog = false
      await this.handleGetPartners()
    },

    async updatePartner(_id) {
      this.partnerId = _id
      this.addKey = true
    },
    async deletePartner() {
      const response = await this.handleDeletePartner(this.partnerRemove._id)
      let message = response.message
      let variant = 'success'

      if (response.errors) {
        message = response.errors
        variant = 'danger'
      }

      this.$bvToast.toast(message, {
        title: 'Aviso',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      this.openDialog = false
      await this.handleGetPartners({
        sortIn: 'desc'
      })
    },
    getUpdateLog(index) {
      this.handleGetUpdateLogByIndex(index)
      this.openDialog = true
    },
    async handleChangeApiKey(payload) {
      const params = {
        ...this.partnersMeta,
        ...payload
      }

      await this.handleGetPartners(params)
    },
    setBreadcrumbTabsName(breadcrumbTabsName){
      this.breadcrumbTabsName = breadcrumbTabsName;
      const breadcrumb = {
        page: 'Relatórios',
        subPage: {
          name: 'Relatórios',
          active: false
        },
        tabs: [
          {
            name: this.breadcrumbTabsName,
            active: true
          }
        ]
      }
      this.setBreadcrumb(breadcrumb)
    },
  },
  async created() {

    await this.handleGetPartners({
      sortIn: 'desc'
    })
    this.setBreadcrumbTabsName('Cadastro de parceiros')
  }
}
</script>

<style lang='scss' scoped>
.none-sublinhado {
  text-decoration: none;
}

.surveys-view {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.search {
  display: flex;
  justify-content: flex-end;
  gap: 4px;

  /deep/ input {
    width: 256px;
  }
}
</style>
