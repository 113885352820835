<template>
  <div class='surveys-table'>
    <b-modal ref='show-log' size='lg' scrollable hide-footer title='Cadastro'>
      <add-update-new :readOnly='false' :log='logUpdate' @close='close' @show-toast-response='showToastResponse' />
    </b-modal>

    <base-table
      :columns='columns'
      :data='partners'
      :total-items='partnersMeta.total'
      :start-items='partnersMeta.skip + 1'
      :max-items='partnersMeta.limit'
      @set-page='handleChangeUpdateLogs'
    >
      <template v-slot:body-user='row'>
        {{ row.item.user && row.item.user.username ? row.item.user.username : '' }}
      </template>

      <template v-slot:body-apiKey='row'>
        <div class='api-key'>
          <span :id="row.item._id">{{ row.item.apiKey }}</span>
        </div>

        <i
          class='fas fa-copy fa-lg'
          @click="copy(row.item._id)"
        />
      </template>

      <template v-slot:body-actions='row'>
        <i
          class='fas fa-pen fa-lg'
          @click='seeMoreUser(row.item._id)'
        />
        <i
          class='fas fa-trash fa-lg'
          @click="$emit('remove-partner', row.item)"
        />

        <i
          class='fas fa-key fa-lg'
          @click="$emit('update-apikey', row.item)"
        />
      </template>
    </base-table>
  </div>
</template>

<script>
import format from 'date-fns/esm/format/index'
import { mapActions } from 'vuex'
import AddUpdateNew from '@/components/UpdateNew/AddUpdateNew'

export default {
  name: 'ApiKeyTable',
  props: {
    partners: { type: Array, require: true },
    partnersMeta: { type: Object, require: true }
  },
  async created() {
  },
  data() {
    return {
      logUpdate: {},
      openDialog: false,
      columns: [
        { key: 'name', value: 'Parceiro' },
        { key: 'email', value: 'E-mail' },
        { key: 'createdAt', value: 'Data cadastro' },
        { key: 'apiKey', value: 'Chave de acesso' },
        { key: 'actions', value: 'Ações' }
      ]
    }
  },
  components: {
    AddUpdateNew
  },
  methods: {
    ...mapActions('apiKey', {
      handleGetPartners: 'handleGetPartners'
    }),
    async copy(id){
      try {
        const content = document.getElementById(id).innerHTML;

        await navigator.clipboard.writeText(content)
          .then(() => {
            this.$bvToast.toast('Chave copiada para área de transferência', {
              title: 'Aviso',
              variant: 'success',
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000
            })
          })
          .catch((err) => {
            this.$bvToast.toast('Não foi possivél copiar a chave para área de transferência', {
              title: 'Aviso',
              variant: 'danger',
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000
            })
          })
      } catch (e) {
        console.log(e);
      }
    },
    async showToastResponse(message) {
      this.$bvToast.toast(message, {
        title: 'Aviso',
        variant: 'success',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      await this.handleGetPartners()
      this.close()
    },
    async seeMoreUser(_id) {
      this.$emit('update-partner', _id)
    },
    formatDate(date) {
      return format(date, 'dd/MM/yyyy')
    },

    handleChangeUpdateLogs(page) {
      const pageCount = page <= 0 ? 1 : page
      const params = {
        skip: ((pageCount - 1) * this.partnersMeta.limit)
      }
      this.$emit('change-partners', params)
    },

    show() {
      this.$refs['show-log'].show()
    },

    close() {
      this.$refs['show-log'].hide()
    }
  }
}
</script>

<style lang='scss' scoped>
.api-key {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.surveys-table {
  display: flex;
  flex-direction: column;
  gap: 16px;

  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}
</style>
